/**
 * @about this file contains api
 */

import axios from "axios";
import { API_ENDPOINT } from "./Constant";
import { encode as btoa } from "base-64";


export const getOccupation = () => {
  let basicAuth = btoa("satish:flash");
  return axios.get(API_ENDPOINT + "/standard_chartered/api/occupation", {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${basicAuth}`,
    },
  });
};

export const getWorkType = () => {
  let basicAuth = btoa("satish:flash");
  return axios.get(API_ENDPOINT + "/standard_chartered/api/work_type", {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${basicAuth}`,
    },
  });
};

export const getDesignation = () => {
  let basicAuth = btoa("satish:flash");
  return axios.get(API_ENDPOINT + "/standard_chartered/api/designation", {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${basicAuth}`,
    },
  });
};

export const getQualification = () => {
  let basicAuth = btoa("satish:flash");
  return axios.get(API_ENDPOINT + "/standard_chartered/api/qualification", {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${basicAuth}`,
    },
  });
};

export const getCompany_details = (value) => {
  console.log("getCompany", value);
  let url = `${API_ENDPOINT}/standard_chartered/api/company_details?search=${
    value ? value : ""
  }`;
  let basicAuth = btoa("satish:flash");
  return axios.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${basicAuth}`,
    },
  });
};

export const getIndustry_isic = () => {
  let basicAuth = btoa("satish:flash");
  return axios.get(API_ENDPOINT + "/standard_chartered/api/industry_isic", {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${basicAuth}`,
    },
  });
};

export const getOther_document_type = () => {
  let basicAuth = btoa("satish:flash");
  return axios.get(
    API_ENDPOINT + "/standard_chartered/api/other_document_type",
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${basicAuth}`,
      },
    }
  );
};

export const getOfficeCityList = () => {
  let basicAuth = btoa("satish:flash");
  return axios.get(API_ENDPOINT + "/standard_chartered/api/residential_city", {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${basicAuth}`,
    },
  });
};

export const isEligible = (data) => {
  let url = `${API_ENDPOINT}/standard_chartered/api/eligibility_check`;
  let basicAuth = btoa("satish:flash");
  return axios.post(url, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${basicAuth}`,
    },
  });
};

export const getPinCode = (value) => {
  let url = `${API_ENDPOINT}/standard_chartered/api/pincode?pincode=${
    value ? value : ""
  }`;
  let basicAuth = btoa("satish:flash");
  return axios.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${basicAuth}`,
    },
  });
};

export const getResidencialType = () => {
  let url = `${API_ENDPOINT}/standard_chartered/api/residential_type`;
  let basicAuth = btoa("satish:flash");
  return axios.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${basicAuth}`,
    },
  });
};

export const getResidentialCity = () => {
  let url = `${API_ENDPOINT}/standard_chartered/api/residential_city`;
  let basicAuth = btoa("satish:flash");
  return axios.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${basicAuth}`,
    },
  });
};

export const SubmitAllDetails = (data) => {
  let url = `${API_ENDPOINT}/standard_chartered/api/submit-details-to-scb`;
  let basicAuth = btoa("satish:flash");
  return axios.post(url, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${basicAuth}`,
    },
  });
};

export const postResidentialDetails = (data) => {
  let url = `${API_ENDPOINT}/standard_chartered/api/submit-residential-details`;
  let basicAuth = btoa("satish:flash");
  return axios.post(url, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${basicAuth}`,
    },
  });
};

export const appFlayerIntegration = (user_id,event_name,token) => {

  let data = {
    user_id:user_id,
    event_name:event_name,
    token:token||""
  }
  let url = `${API_ENDPOINT}/standard_chartered/api/scb-apps-flyer-event-trigger`;
  let basicAuth = btoa("satish:flash");
  axios
    .post(url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${basicAuth}`,
      },
    })
    .catch((err) => {
      console.log(err?.response);
    });
};
