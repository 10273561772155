import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../Images/paymelogo.png";
import SCLogo from "../Images/scl.png";
import { Loader } from "../Loader";
import {
  appFlayerIntegration,
  getOther_document_type,
  SubmitAllDetails,
} from "../Service/ApiService";
import ProgressBar from "./ProgressBar";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { MetaTags } from "react-meta-tags";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const firebaseConfig = {
  apiKey: "AIzaSyD9sTeEaubYm8LeJlfRo_EMrb43WFzeJk4",
  authDomain: "payme-india.firebaseapp.com",
  databaseURL: "https://payme-india.firebaseio.com",
  projectId: "payme-india",
  storageBucket: "payme-india.appspot.com",
  messagingSenderId: "435990090197",
  appId: "1:435990090197:web:bdc0a2ca31304e83ff6d5c",
  measurementId: "G-HDEGFJGY0E",
};

const firebase_initials = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebase_initials);

logEvent(analytics, "landing_page_initiated");

toast.configure();

const DocumentsDetails = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [other_docList, setOther_docList] = useState([]);
  const [other_doc, setOtherDoc] = useState("");
  const [expireDate, setExpireDate] = useState(null);
  const [documentNumber, setDocumentNumber] = useState("");
  const [error, setError] = useState({});

  const [data, setData] = useState(state);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    handleOther_doc();
  }, []);
  const handleOther_doc = () => {
    setLoader(true);
    getOther_document_type()
      .then((res) => {
        setLoader(false);
        setOther_docList(res.data);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  };
  console.log(other_doc);

  const handleSubmittoScb = (e) => {
    e.preventDefault();

    if (!other_doc) {
      setError({ ...error, other_doc: "Document Type can not be blank" });
      return;
    }
    if (!documentNumber) {
      setError({
        ...error,
        documentNumber: "Document Number can not be blank",
      });
      return;
    }

    if (other_doc === "passport" || other_doc === "drivingLicence") {
      if (!expireDate) {
        setError({ ...error, expireDate: "Expire Date can not be blank" });
        return;
      }
    }

    let reqBody = {
      ...data,
      other_doc_type: other_doc,
      other_doc_no: documentNumber,
      other_doc_exp_date: moment(expireDate).format("DD-MM-YYYY"),
    };
    setLoader(true);
    SubmitAllDetails(reqBody)
      .then((res) => {
        logEvent(analytics, "scb_all_details_submit");
        appFlayerIntegration(
          localStorage.getItem("user_id"),
          "scb_all_details_submit"
        );

        console.log(res);
        navigate("/thank_you");
      })
      .catch((err) => {
        toast.error(err?.response?.data.Message || "something went wrong", {
          theme: "colored",
        });
      });
    console.log("finaldate", data);
    setLoader(false);
  };
  console.log(expireDate);

  return (
    <>
      {loader ? <Loader /> : null}

      <MetaTags>
        <noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-KC89XB3"
            height="0"
            width="0"
            style="display:none;visibility:hidden"
          ></iframe>
        </noscript>
      </MetaTags>
      <section>
        <div className="mob-div" style={{ height: "100vh" }}>
          <div className="container">
            <Link to="/">
              {" "}
              <img src={Logo} alt="logo" />
            </Link>
            <div className="mob-div-inner">
              <ProgressBar step={2} />
              <h3>Documents</h3>
              <form>
                <div className="form-input-wrapper">
                  <label for="document-type">Other document type</label>
                  <select
                    name="document-type"
                    id="document-type"
                    value={other_doc}
                    onChange={(e) => {
                      setOtherDoc(e.target.value);
                      setError({ ...error, other_doc: "" });
                    }}
                  >
                    <option value="">Select document type</option>
                    {other_docList &&
                      other_docList.map((data, index) => (
                        <option key={index} value={data.code}>
                          {data.description}{" "}
                        </option>
                      ))}
                  </select>
                  <span style={{ color: "red", marginLeft: "20px" }}>
                    {error?.other_doc}
                  </span>
                </div>
                <div className="form-input-wrapper">
                  <label for="document-number">Other document number</label>
                  <input
                    type="text"
                    name="document-number"
                    id="document-number"
                    placeholder="Enter document number"
                    value={documentNumber}
                    onChange={(e) => {
                      setDocumentNumber(e.target.value);
                      setError({ ...error, documentNumber: "" });
                    }}
                  />
                  <span style={{ color: "red", marginLeft: "20px" }}>
                    {error?.documentNumber}
                  </span>
                </div>
                {other_doc === "passport" || other_doc === "drivingLicence" ? (
                  <div className="form-input-wrapper">
                    <label for="document-number">
                      Other document Expire date
                    </label>

                    <DatePicker
                   
                     
                      onChange={(date) => {
                       
                        setExpireDate(date)

                        setError({ ...error, expireDate: "" });
                      }}
                      dateFormat="d/MM/y"
                      selected={expireDate?expireDate:null}
                      minDate={new Date()}
                      placeholderText="Enter Expire Date"
                      showMonthDropdown
                      showYearDropdown
                    />
                    <span style={{ color: "red", marginLeft: "20px" }}>
                      {error?.expireDate}
                    </span>
                  </div>
                ) : null}
              </form>
            </div>
            <div className="button-wrapper">
              {" "}
              <button
                type="submit"
                className="scbFinalSubmit"
                onClick={handleSubmittoScb}
              >
                Submit
              </button>
            </div>

            <div className="powredby">
              <p>
                Powered by <img src={SCLogo} alt="scl" className="scl" />{" "}
                Standard Chartered Card
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DocumentsDetails;
