import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../Images/paymelogo.png";
import SCLogo from "../Images/scl.png";
import {
  getIndustry_isic,
  getDesignation,
  getOccupation,
  getWorkType,
  getOfficeCityList,
  getPinCode,
  appFlayerIntegration,
} from "../Service/ApiService";
import Select from "react-select";
import { Loader } from "../Loader";
import ProgressBar from "./ProgressBar";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { MetaTags } from "react-meta-tags";

const firebaseConfig = {
  apiKey: "AIzaSyD9sTeEaubYm8LeJlfRo_EMrb43WFzeJk4",
  authDomain: "payme-india.firebaseapp.com",
  databaseURL: "https://payme-india.firebaseio.com",
  projectId: "payme-india",
  storageBucket: "payme-india.appspot.com",
  messagingSenderId: "435990090197",
  appId: "1:435990090197:web:bdc0a2ca31304e83ff6d5c",
  measurementId: "G-HDEGFJGY0E",
};

const firebase_initials = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebase_initials);

logEvent(analytics, "landing_page_initiated");

const ProfessionalDetails = (props) => {
  const navigate =useNavigate()
  const { state } = useLocation();
  console.log("state", state);

  const dataFetchedRef = useRef(false);
  const [input, setInput] = useState();
  const [error, setError] = useState();

  const [occupation, setOccupation] = useState();
  const [workType, setWorkType] = useState();
  const [designation, setDesignation] = useState();
  const [industry_isic, setIndustry_isic] = useState();
  const [OfficeCityList, setOfficeCityList] = useState([]);
  const [officeCity, setOfficeCity] = useState();
  const [officePin, setOfficePin] = useState();
  const[loader,setLoader]=useState(false);
  const[invalidPin, setInvalidPin] = useState(false)
  const[count,setCount]=useState(0)


  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    handleOccupation();
    handleWorkType();
    handleDesignation();
    handleindustry_isic();
    handleOfficeCityList();
  }, []);

  const handleOccupation = () => {
    getOccupation()
      .then((res) => {
        setOccupation(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePinCode = (pincode) => {
    setLoader(true)
    getPinCode(pincode)
      .then((res) => {
        setLoader(false)
       
        if(!res.data.length){
          setCount(count+1)
          if(count>2){
            logEvent(analytics, "office_pincode_not_available");
          }
         
          handleError("Office_pin","This pin code is not available")
        }
     
      })
      .catch((err) => {
        setLoader(false)
        console.log(err);
      });
  };

  const handleDesignation = () => {
    getDesignation()
      .then((res) => {
        setDesignation(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleindustry_isic = () => {
    getIndustry_isic()
      .then((res) => {
        setIndustry_isic(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleWorkType = () => {
    getWorkType()
      .then((res) => {
        setWorkType(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleOfficeCityList = () => {
    getOfficeCityList()
      .then((res) => {
        let city = res.data.map((item) => {
          return {
            label: item?.city,
            value: item?.code,
          };
        });

        setOfficeCityList(city);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let data ={
      qualification:localStorage.getItem('Qualification'),
      occupation:input?.Occupation ,
      work_type:input?.Work_type,
      salary_bank_account: input?.salary_bank_account,
      other_company_name:localStorage.getItem('company_code'),
      total_work_experience: input?.work_experience,
      industry_isic:input?.Industry_ISIC,
      office_address: input?.Office_address,
      office_pincode:officePin,
      office_city: officeCity?.label,
      office_phone_number: input?.Office_phone_number,
      other_doc_type: 'string',
      other_doc_no: 'string',
      other_doc_exp_date: 'string',
      user_id:localStorage.getItem('user_id')
    }


    if (!input?.work_experience) {
      handleError("work_experience", "This field is mandetory");
      return;
    }
    if (!input?.salary_bank_account) {
      handleError("salary_bank_account", "This field is mandetory");
      return;
    }
    if (!input?.Occupation) {
      handleError("Occupation", "This field is mandetory");
      return;
    }
    if (!input?.Work_type) {
      handleError("Work_type", "This field is mandetory");
      return;
    }
  
    if (!input?.Designation) {

      handleError("Designation", "This field is mandetory");
      return;
    }

    if (!input?.Industry_ISIC) {
    
      handleError("Industry_ISIC", "This field is mandetory");
      return;
    }
    if (!input?.Office_phone_number) {
      handleError("Office_phone_number", "This field is mandetory");
      return;
    }
    if (input?.Office_phone_number.length!==10) {
      handleError("Office_phone_number", "Phone number should be 10 digits");
      return;
    }
    if (!input?.Office_address) {
      handleError("Office_address", "This field is mandetory");
      return;
    }

    if (!officeCity) {
      handleError("Office_city", "This field is mandetory");
      return;
    }
    if (!officePin) {
      handleError("Office_pin", "This field is mandetory");
      return;
    }
    if (officePin.length !== 6) {
      handleError("Office_pin", "PinCode must be 6 digits");
      return;
    }


    if(invalidPin){
      logEvent(analytics, "scb_invalid_pin");
      appFlayerIntegration(
        localStorage.getItem("user_id"),
        "scb_invalid_pin"
      );
      
      navigate("/sorry")
    }
else{
  logEvent(analytics, "scb_professional_details");
  appFlayerIntegration(
    localStorage.getItem("user_id"),
    "scb_professional_details"
  );
  
  navigate("/documents_detail",{state:data})

}
  };


  const handleInput = (e) => {
    setError({});

    const { name, value } = e.target;
    if(name==="salary_bank_account")
    {
      setInput({ ...input, [name]: value.slice(0,15) });
    }
else{
    setInput({ ...input, [name]: value });
}
  };

  const handleError = (err, msg) => {
    setError({ ...error, [err]: msg });
  };

  return (
    <>
    

<MetaTags>
        <noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-KC89XB3"
            height="0"
            width="0"
            style="display:none;visibility:hidden"
          ></iframe>
        </noscript>
      </MetaTags>


    {loader?<Loader/>:null}
      <section>
        <div className="mob-div">
          <div className="container">
            <Link to="/">
              {" "}
              <img src={Logo} alt="logo" />
            </Link>

            <div className="mob-div-inner">
            
            <ProgressBar step={1}/>
              <h3>Professional Details</h3>
              <form action="#">
                <div className="form-input-wrapper">
                  <label for="Employment-type">Employment type</label>

                  <select name="Employment_type" id="Employment-type">
                    <option value="Salaried">Salaried</option>
                  </select>
                </div>

                <div className="form-input-wrapper">
                  <label for="work_experience">Work Experience</label>
                  <select
                    name="work_experience"
                    id="work_experience"
                    onChange={handleInput}
                    value={input?.work_experience || ""}
                  >
                    <option value="" disabled>
                      Select Work Experience
                    </option>

                    <option value="1">Less than a year </option>
                    <option value="2">1-2 Year </option>
                    <option value="3">2-3 year </option>
                    <option value="4">3-4 year </option>
                    <option value="5">4-5 year </option>
                    <option value="6"> 5-6 year </option>
                    <option value="7">Greater than 6 year </option>
                  </select>
                  <span style={{ color: "red", marginLeft: "9px" }}>
                    {error?.work_experience}
                  </span>
                </div>

                <div className="form-input-wrapper">
                  <label for="salary_bank_account">Salaried Bank Account</label>
                  <input
                    name="salary_bank_account"
                    type="text"
                    id="salary_bank_account"
                    onChange={(e) => {
                      if (e.target.value.match(/^[0-9\s]*$/)) {
                        handleInput(e);
                      }
                    }}
                    placeholder="Enter Your Salaried Bank Account"
                    value={input?.salary_bank_account || ""}
                  />

                  <span style={{ color: "red", marginLeft: "9px" }}>
                    {error?.salary_bank_account}
                  </span>
                </div>

                <div className="form-input-wrapper">
                  <label for="Occupation">Occupation</label>
                  <select
                    name="Occupation"
                    id="Occupation"
                    onChange={handleInput}
                    value={input?.Occupation || ""}
                  >
                    <option value="" disabled>
                      Select Occupation
                    </option>

                    {occupation &&
                      occupation.map((data, index) => (
                        <option value={data.code}>{data.description} </option>
                      ))}
                  </select>
                  <span style={{ color: "red", marginLeft: "9px" }}>
                    {error?.Occupation}
                  </span>
                </div>
                <div className="form-input-wrapper">
                  <label for="Work_type">Work type</label>
                  <select
                    name="Work_type"
                    id="Work_type"
                    onChange={handleInput}
                    value={input?.Work_type || ""}
                  >
                    <option value="" disabled>
                      Select your work type
                    </option>
                    {workType &&
                      workType.map((data, index) => (
                        <option value={data?.code}>{data.description} </option>
                      ))}

                    <option value="one">one</option>
                    <option value="two">two</option>
                  </select>
                  <span style={{ color: "red", marginLeft: "9px" }}>
                    {error?.Work_type}
                  </span>
                </div>
                {/* <div className="form-input-wrapper">
                  <label for="Employer_name">Employer name</label>
                  <input
                    type="text"
                    name="Employer_name"
                    id="Employer_name"
                    maxLength={20}
                    placeholder="Enter your Employer name"
                    onChange={(e) => {
                      if (e.target.value.match("^[a-zA-Z]*$")) {
                        handleInput(e);
                      }
                    }}
                  />
                  <span style={{ color: "red", marginLeft: "9px" }}>
                    {error?.Employer_name}
                  </span>
                </div> */}

                {/* <div className="form-input-wrapper">
                  <label for="Company_code">Company code</label>
                  <select
                    name="Company_code"
                    id="Company_code"
                    onChange={handleInput}
                    value={input?.Company_code}
                  >
                    <option value="" disabled>
                      Select Company code
                    </option>
                    


                    
                  </select>
                  <span style={{ color: "red", marginLeft: "9px" }}>
                    {error?.Company_code}
                  </span>
                </div> */}
                <div className="form-input-wrapper">
                  <label for="Designation">Designation</label>
                  <select
                    name="Designation"
                    id="Designation"
                    onChange={handleInput}
                    value={input?.Designation || ""}
                  >
                    <option value="" disabled>
                      Select Designation
                    </option>

                    {designation &&
                      designation.map((data, index) => (
                        <option value={data?.code}>
                          {data.designation + data.description}{" "}
                        </option>
                      ))}
                  </select>
                  <span style={{ color: "red", marginLeft: "9px" }}>
                    {error?.Designation}
                  </span>
                </div>
                <div className="form-input-wrapper">
                  <label for="Industry_ISIC">Industry ISIC</label>
                  <select
                    name="Industry_ISIC"
                    id="Industry_ISIC"
                    onChange={handleInput}
                    value={input?.Industry_ISIC || ""}
                  >
                    <option value="" disabled>
                      Select Industry-ISIC
                    </option>
                    {industry_isic &&
                      industry_isic.map((data, index) => (
                        <option value={data?.code}>{data?.description} </option>
                      ))}
                  </select>
                  <span style={{ color: "red", marginLeft: "9px" }}>
                    {error?.Industry_ISIC}
                  </span>
                </div>
                <div className="form-input-wrapper">
                  <label for="Office_phone_number">Office phone number</label>
                  <input
                    name="Office_phone_number"
                    id="Office_phone_number"
                    value={input?.Office_phone_number}
                    onChange={(e) => {
                      handleError("Office_phone_number","")
                      setInput({
                        ...input,
                        Office_phone_number: e.target.value
                          .slice(0, 10)
                          .replace(/\D/g, ""),
                      });
                    }}
                    value={input?.Office_phone_number || ""}
                    placeholder="Enter Office Phone Number"
                  />

                  <span style={{ color: "red", marginLeft: "9px" }}>
                    {error?.Office_phone_number}
                  </span>
                </div>

                <div className="form-input-wrapper">
                  <label for="Office_address">Office address</label>
                  <input
                    type="text"
                    name="Office_address"
                    id="Office_address"
                    placeholder="Enter your Office address 1"
                    onChange={(e) => {
                      if (e.target.value.match("^[a-zA-Z]*$")) {
                        handleInput(e);
                      }
                    }}
                  />
                </div>
                <span style={{ color: "red", marginLeft: "9px" }}>
                  {error?.Office_address}
                </span>

                <div className="form-input-wrapper">
                  <label for="Office_city">Office city </label>

                  <Select
                    value={officeCity}
                    onChange={(e) => {
                      handleError("Office_city","")
                      setOfficeCity(e);
                    }}
                    options={OfficeCityList}
                  />

                  <span style={{ color: "red", marginLeft: "9px" }}>
                    {error?.Office_city}
                  </span>
                </div>

                <div className="form-input-wrapper">
                  <label for="Office_pin">Office pin </label>
                  <input
                    name="Office_pin"
                    type="text"
                    id="Office_pin"
                    value={officePin}
                    maxLength={6}
                    onChange={(e) => {
                      handleError("Office_pin",'')
                      setOfficePin(
                        e.target.value.slice(0, 6).replace(/\D/g, "")
                      );
                      if(e.target.value.length===6)
                      {
                        handlePinCode(
                          e.target.value.slice(0, 6).replace(/\D/g, "")
                        );
                      }
                     
                    }}
                    placeholder="Enter Office pin"
                  />

                  <span style={{ color: "red", marginLeft: "9px" }}>
                    {error?.Office_pin}
                  </span>
                </div>
              </form>
            </div>
            {/* <div className="checkbox-sc">
                    <label className="main-check">I have read the above and agree to the terms
                        <input type="checkbox" />
                        <span className="geekmark"></span>
                    </label>
                </div> */}
            <div className="button-wrapper">
              <button type="submit" className="scbprofessionalDetailSubmit" onClick={handleSubmit}>
                Continue
              </button>
            </div>
            <div className="powredby">
              <p>
                Powered by <img src={SCLogo} alt="scl" className="scl" />{" "}
                Standard Chartered Card
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProfessionalDetails;
