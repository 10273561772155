import React, { lazy } from "react";
import {  Route, Routes } from "react-router-dom";

import "./custom.scss";
import DocumentsDetails from "./Pages/DocumentsDetails";
import FinalStep from "./Pages/FinalStep";
import ProfessionalDetails from "./Pages/ProfessionalDetails";
import NotEligible from "./Pages/NotEligible";
const EligibilityCheck = lazy(()=>import ("./Pages/EligibilityCheck"))
const ResidencialDetails = lazy(()=>import ("./Pages/ResidencialDetails"))

const Layout = () => {
  return (
    <Routes>
      <Route path="/" element={<EligibilityCheck />} />
      <Route path="/professional_detail" element={<ProfessionalDetails />} />
      <Route path="/documents_detail" element={<DocumentsDetails />} />
      <Route path="/thank_you" element={<FinalStep />} />
      <Route path="/residencial_details" element={<ResidencialDetails/>} />
      <Route path="/sorry" element={<NotEligible/>} />
    
    </Routes>
  );
};

export default Layout;
