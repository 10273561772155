import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import './App.css';
import Layout from "./Layout";
import { Loader } from "./Loader";



function App() {
  return(
  <BrowserRouter>
   <Suspense fallback={<div><Loader/> </div>}>   <Layout />
  </Suspense>

</BrowserRouter>
  )
  
}

export default App;
