import React, { useEffect, useRef } from "react";
import Logo from "../Images/paymelogo.png";
import SCLogo from "../Images/scl.png";
import sorry from "../Images/sorry.png";
import { Link } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { MetaTags } from "react-meta-tags";

const firebaseConfig = {
  apiKey: "AIzaSyD9sTeEaubYm8LeJlfRo_EMrb43WFzeJk4",
  authDomain: "payme-india.firebaseapp.com",
  databaseURL: "https://payme-india.firebaseio.com",
  projectId: "payme-india",
  storageBucket: "payme-india.appspot.com",
  messagingSenderId: "435990090197",
  appId: "1:435990090197:web:bdc0a2ca31304e83ff6d5c",
  measurementId: "G-HDEGFJGY0E",
};

const firebase_initials = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebase_initials);

logEvent(analytics, "landing_page_initiated");

const NoEligible = () => {
  const dataFetchedRef = useRef(false);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    logEvent(analytics, "scb_sorryPage");
  }, []);

  return (
    <>
      <MetaTags>
        <noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-KC89XB3"
            height="0"
            width="0"
            style="display:none;visibility:hidden"
          ></iframe>
        </noscript>
      </MetaTags>
      <section>
        <div className="mob-div" style={{ height: "100vh" }}>
          <div className="container">
            <Link to="/">
              {" "}
              <img src={Logo} alt="logo" />
            </Link>

            <div className="mob-div-inner final_step">
              <img src={sorry} alt="logo" className="final_img" />
              <p>You are not eligible.</p>
              <p>Please try later.</p>
            </div>
            <div className="button-wrapper">
              <Link to="/">
                {" "}
                <button className="scbBacktoHome" className="white_btn">
                  Back to home
                </button>
              </Link>
            </div>
            <div className="powredby">
              <p>
                Powered by <img src={SCLogo} alt="scl" className="scl" />{" "}
                Standard Chartered Card
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NoEligible;
