import React from "react";
import StepProgressBar from "react-step-progress";
import "react-step-progress/dist/index.css";

const ProgressBar = ({step}) => {


  return (
    <div>
      <StepProgressBar
        startingStep={step}
        steps={[
          {
            subtitle: "20%",
          },
          {
            subtitle: "80%",
          },
          {
            subtitle: "100%",
          },
        ]}
      />
    </div>
  );
};

export default ProgressBar;
